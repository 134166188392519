<template>
  <app-module-view>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <button
                type="button"
                class="btn btn-success"
                @click="save"
              >
                <i class="fa fa-save "></i> {{ $t('buttons.save') }}
              </button>
              <app-button-delete v-if="tasrNewsCategory.id" @deleteRecord="deleteTasrNewsCategory"></app-button-delete>
              <app-button-close route-name="tasrNewsCategory_list"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <div class="card">
        <div class="card-body">
          <section class="content form-horizontal">
            <div class="box box-info">
              <div class="box-body">
                <div class="row">
                  <div class="col-lg-12">
                    <input type="hidden" v-model="tasrNewsCategory.id">
                    <app-select
                      v-model="tasrNewsCategory.site"
                      :options="sites"
                      :label="$t('tasrNewsCategory.site')"
                      id="tasrNewsCategory_site"
                      @change="loadRubricsBySite"
                    >
                    </app-select>
                    <app-select
                      v-model="tasrNewsCategory.rubric"
                      :options="rubrics"
                      :label="$t('tasrNewsCategory.rubric')"
                      id="tasrNewsCategory_rubric"
                    >
                    </app-select>
                    <app-select
                      v-model="tasrNewsCategory.service"
                      :options="valueObjectServiceType"
                      :label="$t('tasrNewsCategory.service')"
                      id="tasrNewsCategory_service"
                      noEmptyValue
                    >
                    </app-select>
                    <app-input
                      v-model="tasrNewsCategory.code"
                      @blur="$v.tasrNewsCategory.code.$touch()"
                      :error="$v.tasrNewsCategory.code.$error"
                      id="tasrNewsCategory_code"
                      :label="$t('tasrNewsCategory.code')"
                    >
                    </app-input>
                    <app-input
                      v-model="tasrNewsCategory.title"
                      @blur="$v.tasrNewsCategory.title.$touch()"
                      :error="$v.tasrNewsCategory.title.$error"
                      id="tasrNewsCategory_title"
                      :label="$t('tasrNewsCategory.title')"
                    >
                    </app-input>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import Input from '../form/inputs/Input'
import Select from '../form/select/Select'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import TasrNewsCategoryModel from '../../model/TasrNewsCategory'
import ButtonDelete from '../shared/ButtonDelete'
import ButtonClose from '../shared/ButtonClose'
import NotifyService from '../../services/NotifyService'
import ValueObjectService from '../../services/ValueObjectService'

export default {
  name: 'TasrNewsCategoryNew',
  data () {
    return {
      dataLoaded: false,
      isLoading: false,
      tasrNewsCategory: this._.cloneDeep(TasrNewsCategoryModel),
      rubrics: []
    }
  },
  computed: {
    valueObjectServiceType () {
      return ValueObjectService.getAsArray(this.$store.getters['tasrNews/valueObjectServiceType'])
    },
    sites () {
      return this.$store.getters['site/enabledSites']()
    }
  },
  validations: {
    tasrNewsCategory: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(250)
      },
      code: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(3)
      }
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appSelect: Select,
    appButtonDelete: ButtonDelete,
    appButtonClose: ButtonClose
  },
  methods: {
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
      } else {
        this.$store.dispatch('tasrNewsCategory/create', this.tasrNewsCategory)
          .then(() => {
            if (this.$store.getters['tasrNewsCategory/error'] === null) {
              NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
              this.tasrNewsCategory = this.$store.getters['tasrNewsCategory/detail']
              this.$router.push('/tasrNewsCategory/' + this.tasrNewsCategory.id + '/edit')
            } else {
              NotifyService.setErrorMessage(this.$store.getters['tasrNewsCategory/error'])
            }
          })
          .catch(error => console.log(error))
      }
    },
    deleteTasrNewsCategory () {
      this.$store.dispatch('tasrNewsCategory/deleteRecord', this.tasrNewsCategory)
        .then(() => {
          if (this.$store.getters['tasrNewsCategory/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/tasrNewsCategory')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['tasrNewsCategory/error'])
          }
        })
        .catch(error => console.log(error))
    },
    async loadRubricsBySite () {
      await this.$store.dispatch('rubric/fetchRubricsBySiteId', this.tasrNewsCategory.site)
      this.rubrics = this.$store.state.rubric.all.filter(item => item.site === this.tasrNewsCategory.site)
    }
  },
  beforeMount () {
    this.$store.dispatch('tasrNews/fetchValueObjectServiceType')
  }
}
</script>
